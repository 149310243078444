import {POST, GET, UPLOAD, DELETE, PUT, MIXED} from './ApiContract';

/************* LOADS *****************/
export const LoadsListService = (payload) => {
    return POST('clients/loads/list', payload);
}
export const AssignLoadCarrier = (payload) => {
    return POST('clients/loads/assign', payload);

}
export const DownloadPodService = (payload) => {
    return GET('clients/loads/pod/download', payload);
}

export const DeliveredUploadService = (payload) => {
    return UPLOAD('clients/loads/deliver', payload);

}
export const DeleteLoadService = (payload) => {
    return DELETE('clients/loads/delete', null, payload);

}
export const DispatchLoadService = (payload) => {
    return POST('clients/loads/dispatch', payload);

}


export const InactiveLoadList = (payload) => {
    return POST('clients/loads/inactivelist', payload);
}
export const LoadsAdd = (payload) => {
    return POST('clients/loads/add', payload);
}
export const LoadsEdit = (payload) => {
    return POST('clients/loads/edit', payload);
}
export const GetLoadsById = (payload) => {
    return GET('clients/loads/get', payload);
}
export const CarrierCategory = (payload) => {
    return GET('clients/carriers/all', payload);
}
export const ShipperCategory = (payload) => {
    return GET('clients/shippers/all', payload);
}
