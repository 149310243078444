import {POST, GET, UPLOAD, DELETE, PUT} from './ApiContract';

export const VendorListService = (payload) => {
    return POST('clients/vendors/list', payload);
}

export const GetVendorByIdService = (payload) => {
    return GET('clients/vendors/get', payload);
}

export const DuplicateVendorListService = (payload) => {
    return GET('clients/vendors/duplicates', payload);
}

export const VendorDataFilesService = (payload) => {
    return POST('clients/vendors/crm/files/list', payload);
}

export const VendorDataFileUploadService = (payload) => {
    return UPLOAD('clients/vendors/files/upload', payload);
}

export const VendorDeleteService = (payload) => {
    return DELETE('clients/vendors/delete', null, payload);
}

export const VendorUpdateService = (payload) => {
    return UPLOAD('clients/vendors/edit', payload);
}

export const VendorAddService = (payload) => {
    return UPLOAD('clients/vendors/add', payload);
}

export const VendorSelectedDeleteService = (payload) => {
    return DELETE('clients/vendors/deleteselected', null, payload);
}

export const VendorDeleteAllService = (payload) => {
    return DELETE('clients/vendors/deleteall', null, payload);
}

export const VendorCategoryListService = (payload) => {
    return GET('clients/categories/list', payload);
}

export const GetMergeMetaDataVendorService = (payload) => {
    return GET('clients/vendors/category/list', payload);
}

export const SetPrimaryVendorService = (payload) => {
    return PUT('clients/vendors/setprimary', null, payload);
}

export const MergeVendorService = (payload) => {
    return POST('clients/vendors/merge', payload);
}

export const SetDuplicateVendorService = (payload) => {
    return PUT('clients/vendors/setduplicate', null, payload);
}

export const GetVendorCategoriesService = (payload) => {
    return GET('clients/vendors/category/list', payload);
}

export const AddVendorCategoryService = (payload) => {
    return POST('clients/vendors/category/add', payload);
}

export const InactiveVendorsListService = (payload) => {
    return POST('clients/vendors/inactivelist', payload);
}

export const FilteredVendorFieldService = (payload) => {
    return GET('users/metadata/filteredfields?tableName=vendors', payload);
}

export const VendorMatchpointService = (payload) => {
    return GET('clients/vendors/matchpoints/list', payload);
}

export const VendorFilteredQueryService = (payload) => {
    return PUT('users/metadata/filteredfields/change', null, payload);
}

export const VendorMatchpointQueryService = (payload) => {
    return PUT('clients/vendors/matchpoints/change', null, payload);
}

export const VendorDownloadService = (payload) => {
    return GET('clients/vendors/files/download', payload);
}

export const VendorTemplate = (payload) => {
    return GET('clients/vendors/template/download', payload);
}
/*******VENDOR CONTACT SERVICE*************/
export const VendorContactService = (payload) => {
    return GET('clients/contacts/list', payload);
}

export const EditContact = (payload) => {
    return POST('clients/contacts/edit', payload);
}

export const VerifyVendorService = (payload) => {
    return POST('clients/vendors/verify', payload);
}

export const UnVerifyvendorService = (payload) => {
    return POST('clients/vendors/unverify', payload);
}
/**************8 Summary **************/
export const VendorSummaryListService = (payload) => {
    return POST('clients/vendorsummary/list', payload);
}

export const VendorVerifiedSummaryListService = (payload) => {
    return POST('clients/vendorsummary/verified', payload);
}

export const SendEmailService = (payload) => {
    return GET('clients/contactus/sendinmail', payload);
}